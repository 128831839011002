<template>
<div class="tv-iframe-wrapper" style="position: absolute;top: 120px;left: 0px;right: 0;bottom: 0;width: 100%;overflow: hidden;"> 
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <!-- <div class="market-profile-inner">
            <div class="market-profile-filter-box" style="padding: 10px 15px 0;">
                <div class="w-100 tgju-btn tgju-btn-lg tgju-btn-primary" onclick="tv_select('tradingview');" style="padding: 9px 15px 13px;margin-bottom: 10px;"><i class="uil uil-th"></i> انتخاب شاخص</div>
            </div>           
        </div> -->

        <!-- <div class="tv-btns-wrapper">
            <div class="tv-fullscreen" onclick="if (!window.__cfRLUnblockHandlers) return false; $(this).parents('.tv-iframe-wrapper').toggleClass('fullscreen'); return false;" data-target="profile-tour-step-13"><i class="fa fa-television"></i> تمام صفحه</div>
            <h2 style="display: inline-block;"><a class="tv-help" href="http://drive.tgju.org/etc/output_Mk086a.gif" target="_blank" style="display: block; right: 262px" data-target="profile-tour-step-12"><i class="fa fa-question"></i> راهنما</a></h2>
            <h2 style="display: inline-block;"><a class="tv-select" href="#" onclick="if (!window.__cfRLUnblockHandlers) return false; tv_select('tradingview'); return false;" style="display: block; background: #555; border-color: #555; right: 330px; padding-left: 10px; padding-right: 10px;" data-target="profile-tour-step-11"><i class="fa fa-th"></i> انتخاب نماد</a></h2>
        </div> -->

        <iframe id="tv-iframe" :src="`https://www.tgju.org/panel/tv4/?symbol=${ market_technical.info.key }`" style="border-color: rgb(216, 219, 221) currentcolor currentcolor; border-style: solid none none; border-width: 1px 0px 0px; border-image: none 100% / 1 / 0 stretch;  display: inline-block;  min-height: unset !important; overflow: hidden;position: absolute;right: 0px;left: 0;top: 0;bottom: 0;height: 100% !important;width: 100%;"></iframe>
    </slot>
</div>
</template>

<script>
export default {
    name: "MarketTechnicalComponent",
    data() {
        return {
            widgetLoad: true,
            market_technical: [],
        }
    },
    mounted() {
        this.getProfileTechnical();
    },
    methods: {
        getProfileTechnical() {
            this.$helpers
                .makeRequest("GET", `/market/profile-technical/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_technical = api_response.data.response.detail.response;
                        this.$parent.title = this.market_technical.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
    },
}
</script>